import Link from 'next/link';
import { PropsWithChildren, useState } from 'react';
import {
  Button,
  faChevronDown,
  faChevronUp,
  Icon,
  Panel,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'upbound-frontend-elements';

import logo from '@/assets/logo.svg';

import { marketingHome } from '../../constants/links';
import { useGetCurrentUserProfileQuery } from '../../generated/upbound-graphql';
import { logoutUrl, settingsProfileUrl } from '../../routes';
import PageTitle from '../PageTitle';

const dropdownLinkStyle = 'text-sm py-1.5 block  px-2.5 rounded-sm hover:bg-neutral-shades-50';

const AccountPopover: React.FC<{
  currentUser: NonNullable<ReturnType<typeof useGetCurrentUserProfileQuery>['data']>['currentUser'];
}> = ({ currentUser }) => {
  const [isAccountPopoverOpen, setIsAccountPopoverOpen] = useState(false);

  return (
    <Popover open={isAccountPopoverOpen} onOpenChange={setIsAccountPopoverOpen}>
      <PopoverTrigger className="flex items-center text-base p-1" asChild>
        <Button btnType="Secondary" btnSize="Small">
          {currentUser.firstName}
          {` `}
          {currentUser.lastName}
          <Icon icon={isAccountPopoverOpen ? faChevronUp : faChevronDown} className="ml-2 text-xs" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        asChild
        className="bg-neutral-0 p-1 mr-1 border border-solid border-neutral-shades-150 w-auto min-w-52"
      >
        <ul className="!text-neutral-1000 !p-1 !m-0">
          <li>
            <Link href={settingsProfileUrl()} className={dropdownLinkStyle}>
              My Account
            </Link>
          </li>
          <li>
            <Link href={logoutUrl()} className={dropdownLinkStyle}>
              Logout
            </Link>
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
};

const PanelLayout: React.FC<
  PropsWithChildren & {
    title?: string;
  }
> = ({ children, title = 'Select an organization - Upbound' }) => {
  const { data } = useGetCurrentUserProfileQuery();

  const currentUser = data?.currentUser;

  if (!currentUser) {
    return null;
  }

  return (
    <main className="flex flex-col items-center min-h-screen bg-neutral-shades-25">
      <PageTitle title={title} />
      <article className="flex-1 w-full flex flex-col">
        <header className="flex justify-between p-4 w-full">
          <Link href={marketingHome.url()} className="flex-1 max-w-28">
            <img src={logo} alt="upbound" className="w-full" id="upbound_logo-auth" />
          </Link>
          <AccountPopover currentUser={currentUser} />
        </header>
        <div className="flex flex-1 justify-center items-center">
          <Panel className="flex flex-1 flex-col px-0 divide-y divide-solid divide-neutral-100 w-full max-w-5xl mx-auto">
            {children}
          </Panel>
        </div>
      </article>
    </main>
  );
};

export default PanelLayout;
