import { capitalCase } from 'change-case';
import Head from 'next/head';

import * as favicons from '@/assets/favicons';
import { CONFIG_SENTRY_ENVIRONMENT } from '@/constants/config';

const environment = capitalCase(CONFIG_SENTRY_ENVIRONMENT);

const { favicon270 } = (() => {
  switch (CONFIG_SENTRY_ENVIRONMENT) {
    case 'production':
      return favicons.production;
    case 'dev':
    default:
      return favicons.development;
  }
})();

const summary =
  'Upbound Accounts is where developers manage their Upbound account for building their internal cloud platform.';

const PageTitle: React.FC<{ title?: string; description?: string }> = ({ title, description }) => {
  if (title) {
    return (
      <Head>
        <title>{title}</title>
        <meta name="application-name" content={`Upbound Accounts`} />

        <meta name="twitter:title" content={title} />
        <meta name="twitter:site" content="@upbound_io" />
        <meta name="twitter:description" content={description || summary} />
        <meta name="twitter:image" content={favicon270} />
        <meta name="twitter:card" content="summary" />
      </Head>
    );
  }

  if (CONFIG_SENTRY_ENVIRONMENT !== 'production') {
    return (
      <Head>
        <title>{`Upbound Accounts ${environment}`}</title>
        <meta name="application-name" content={`Upbound Accounts - ${environment}`} />

        <meta name="twitter:title" content={`Upbound Accounts - ${environment}`} />
        <meta name="twitter:site" content="@upbound_io" />
        <meta name="twitter:description" content={description || summary} />
        <meta name="twitter:image" content={favicon270} />
        <meta name="twitter:card" content="summary" />
      </Head>
    );
  }

  return (
    <Head>
      <title>Upbound Accounts</title>
      <meta name="application-name" content="Upbound Accounts" />

      <meta name="twitter:title" content="Upbound Accounts" />
      <meta name="twitter:site" content="@upbound_io" />
      <meta name="twitter:description" content={description || summary} />
      <meta name="twitter:image" content={favicon270} />
      <meta name="twitter:card" content="summary" />
    </Head>
  );
};

export default PageTitle;
