import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { Button, faPlus, Header, Icon, Paragraph } from 'upbound-frontend-elements';

import { AvatarPlaceholder } from '../components/AvatarPlaceholder';
import PanelLayout from '../components/OrgSelect/PanelLayout';
import { consoleAppOrg, marketplaceBrowse } from '../constants/links';
import { OrgAccount, useGetAllOrgAccountsQuery, useGetCurrentUserProfileQuery } from '../generated/upbound-graphql';
import { createOrgUrl, loginUrl } from '../routes';
import { useIsLoggedIn } from '../utils/auth';

enum SelectDirection {
  Console = 'Console',
  Marketplace = 'Marketplace',
}

const SelectOrg: React.FC = () => {
  const router = useRouter();
  const [isLoggedIn] = useIsLoggedIn();
  const { data } = useGetCurrentUserProfileQuery();
  const { data: nsData } = useGetAllOrgAccountsQuery();

  useEffect(() => {
    if (!isLoggedIn) {
      router.replace(loginUrl());
    }
  }, [isLoggedIn, router]);

  const userOrgs = useMemo(
    () =>
      nsData?.accounts
        .filter((account): account is OrgAccount => account.__typename === 'OrgAccount')
        .map(account => account.organization),
    [nsData?.accounts],
  );

  const currentUser = data?.currentUser;

  if (!currentUser) {
    return null;
  }

  const onCreateClick = () => {
    router.push(createOrgUrl());
  };

  const onSelect = (account: string, direction: SelectDirection) => () => {
    const path = router.asPath;
    const searchParams = path.includes('?') ? `?${new URLSearchParams(path.split('?')[1])}` : '';

    if (direction === SelectDirection.Marketplace) {
      router.push(marketplaceBrowse.url());
    } else if (direction === SelectDirection.Console) {
      router.push(consoleAppOrg.url(account) + searchParams);
    }
  };

  return (
    <PanelLayout title="Select an organization - Upbound">
      <header className="px-5 flex pb-5">
        <div>
          <Header type="h5" className="capitalize">
            My Organizations
          </Header>
          <Paragraph className="!text-neutral-600">Select an organization or create a new one.</Paragraph>
        </div>

        <Button btnType="Primary" className="ml-auto self-start" onClick={onCreateClick}>
          <Icon icon={faPlus} className="mr-2" />
          Create Organization
        </Button>
      </header>
      <div className="flex-1 items-center justify-center content-center">
        {!userOrgs?.length && (
          <Paragraph className="!text-neutral-600 p-5 text-center my-4" textSize="Size3">
            You are not a member of any organizations.
            <br />
            Create one to get started.
          </Paragraph>
        )}
        {userOrgs?.map(o => (
          <div
            className="flex items-center p-5 border-solid border-neutral-100 bg-neutral-0 [&:not(&:last-child)]:border-b [&:last-child]:-mb-4"
            key={o.id}
          >
            <AvatarPlaceholder type="OrgAccount" organization={o} size={32} />
            <div className="fle flex-row pl-3">
              <p className="text-teal-800 font-bold">{o.displayName}</p>
              <p className="text-neutral-600 text-sm capitalize">{o.role.toLowerCase()}</p>
            </div>
            <div className="flex grow justify-end">
              <Button btnType="Secondary" onClick={onSelect(o.name, SelectDirection.Console)}>
                Open Console
              </Button>
              <Button btnType="Secondary" onClick={onSelect(o.name, SelectDirection.Marketplace)} className="ml-3">
                Explore Marketplace
              </Button>
            </div>
          </div>
        ))}
      </div>
    </PanelLayout>
  );
};

export default SelectOrg;
